<template>
  <div class="wrap">
    <p class="title">直播设置</p>
    <a-form v-bind="formItemLayout">
      <a-form-item label="默认分辨率">
        <a-select v-model:value="distinguishability" placeholder="请选择默认分辨率">
          <a-select-option v-for="item in resolutionList" :value="item.value" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="默认分享时效">
        <div style="display: flex">
          <a-input-number
            v-model:value="shareTime"
            :min="1"
            placeholder="请选择默认分享时效"
            :formatter="value => value.replace(/\^(0+)|[^\d]+/g, '')"
            :parser="value => value.replace(/\^(0+)|[^\d]+/g, '')"
          />
          <a-select v-model:value="shareTimeType" style="width: 80px">
            <a-select-option v-for="item in unitList" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </a-form-item>
      <a-form-item label="默认推送时效">
        <div style="display: flex">
          <a-input-number
            v-model:value="pushTime"
            :min="1"
            placeholder="请选择默认分享时效"
            :formatter="value => value.replace(/\^(0+)|[^\d]+/g, '')"
            :parser="value => value.replace(/\^(0+)|[^\d]+/g, '')"
          />
          <a-select v-model:value="pushTimeType" style="width: 80px">
            <a-select-option v-for="item in unitList" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </a-form-item>
      <a-form-item label="强制关闭后再开启时间">
        <div style="display: flex">
          <a-input-number
            v-model:value="closeCD"
            :min="1"
            placeholder="请输入时间"
            :formatter="value => value.replace(/\^(0+)|[^\d]+/g, '')"
            :parser="value => value.replace(/\^(0+)|[^\d]+/g, '')"
          />
          <a-select v-model:value="closeCDType" style="width: 80px">
            <a-select-option v-for="item in closeUnitList" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </a-form-item>
      <a-form-item label="踢出后再次进入时间">
        <div style="display: flex">
          <a-input-number
            v-model:value="kickCD"
            :min="1"
            placeholder="请输入时间"
            :formatter="value => value.replace(/\^(0+)|[^\d]+/g, '')"
            :parser="value => value.replace(/\^(0+)|[^\d]+/g, '')"
          />
          <a-select v-model:value="kickCDType" style="width: 80px">
            <a-select-option v-for="item in kickUnitList" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </a-form-item>
      <a-form-item label="默认直播封面">
        <a-upload-dragger
          class="cover-file"
          :file-list="fileList"
          :remove="removeFile"
          :customRequest="selfUpload"
          @change="handleChange"
        >
          <p class="ant-upload-drag-icon" v-show="!picBase64">
            <plus-outlined></plus-outlined>
          </p>
          <img :src="picBase64" v-show="picBase64" class="cover-img" />
        </a-upload-dragger>
      </a-form-item>
      <a-form-item label="直播标签">
        <a-button @click="jumpToTagManage">标签管理</a-button>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 10 }">
        <a-button type="primary" @click="setLiveSetting">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import api from '@/services';
import constant from '@/utils/common';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    PlusOutlined
  },
  setup() {
    const router = useRouter();
    const unitList = reactive({ ...constant.unitList });
    const closeUnitList = reactive({ ...constant.closeUnitList });
    const kickUnitList = reactive({ ...constant.kickUnitList });
    const resolutionList = reactive({ ...constant.resolutionList });

    const fileList = ref([]); // 上传图片列表

    const formItemLayout = reactive({
      labelCol: { span: 4, offset: 3 },
      wrapperCol: { span: 10 }
    });
    // 表单状态
    const formState = reactive({
      distinguishability: 1, // 分辨率 type
      shareTime: '', // 分享时效
      shareTimeType: 1, // 分享时效时间单位 type
      pushTime: '', // 推送时效时间
      pushTimeType: 1, // 推送时效时间单位 type
      closeCD: '', // 强制关闭时效时间
      closeCDType: 1, // 强制关闭时间单位 type
      kickCD: '', // 踢出时效时间
      kickCDType: 1, // 踢出时间单位 type
      picBase64: '' // 封面 base64
    });

    onMounted(() => {
      getLiveSetting();
    });

    const getLiveSetting = async () => {
      const { success, data } = await api.getLiveSetting();
      if (success) {
        Object.keys(formState).map(item => {
          formState[item] = data[item];
        });
        formState.picBase64 = 'data:image/png;base64,' + data.picBase64;
      }
    };

    // 保存设置
    const setLiveSetting = async () => {
      let params = {
        ...formState
      };
      const { success } = await api.setLiveSetting(params);
      if (success) {
        message.success('设置成功！');
      }
    };

    // 跳转到标签管理页面
    const jumpToTagManage = () => {
      router.push('/admin/business-manage/tag-manage');
    };

    // base64转换
    const selfUpload = ({ file }) => {
      new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
          formState.picBase64 = fileReader.result;
        };
      });
    };
    // 修改上传状态 和 限制上传数量
    const handleChange = info => {
      let resFileList = [...info.fileList];
      resFileList = resFileList.slice(-1);
      fileList.value = resFileList;
      if (fileList.value.length) fileList.value[0].status = 'done';
    };

    // 删除
    const removeFile = () => {
      formState.picBase64 = '';
    };

    return {
      getLiveSetting,
      setLiveSetting,
      fileList,
      unitList,
      closeUnitList,
      kickUnitList,
      selfUpload,
      handleChange,
      removeFile,
      resolutionList,
      formItemLayout,
      ...toRefs(formState),
      jumpToTagManage
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  .title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .ant-form {
    margin-top: 30px;
    :deep(.ant-form-item) {
      .ant-upload-drag-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.anticon-plus) {
          color: #d4d4d4;
          font-size: 24px;
        }
      }
      .cover-img {
        height: 148px;
        border-radius: 10px;
      }
    }
  }
}

:deep(.ant-input-number-handler-wrap) {
  display: none;
}
.ant-input-number {
  flex: 1;
}
</style>
